

























































































































































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import { Role } from "@/models/role.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { User } from "@/models/user.interface";
import VueCountryCode from "vue-country-code";
import { Status } from "@/models/status.interface";
import LanguagePicker from "@/components/Input/LanguagePicker.vue";
import { StyleCheck } from "@/mixins/style-check";
import EmailChecker from "@/components/Input/EmailChecker.vue";
import AliasChecker from "@/components/Input/AliasChecker.vue";
import { UserValidations } from "@/mixins/user-validations";
import DotsMenu from "@/components/General/DotsMenu.vue";

@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    VueCountryCode,
    LanguagePicker,
    EmailChecker,
    AliasChecker,
    DotsMenu,
  },
})
export default class Users extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  StyleCheck,
  UserValidations
) {
  $refs!: {
    userForm: HTMLFormElement;
    updateForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  dialogUpdate = false;
  dialogLink = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  search = "";
  options = {};
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };
  tab = 0;
  phoneCountry: { dialCode: string; iso2: string } = { dialCode: "", iso2: "" };
  formatedPhone = "";
  userValidations = {
    alias: false,
    editAlias: false,
    editEmail: false,
    loadingAlias: false,
    email: false,
    loadingEmail: false,
  };
  defaultUser: User = {
    id: undefined,
    identification: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    alias: "",
    block_product_comments: false,
    block_bill_comments: false,
    status: {
      name: "",
    },
    language: "es",
  };
  formerUser: Partial<User> = {
    email: undefined,
  };
  defaultRole: Role = {
    name: "",
  };
  user: User = { ...this.defaultUser };
  role: Role = { ...this.defaultRole };
  get myUser(): User {
    return this.$store.getters["users/getUser"];
  }
  @Watch("tab")
  private async changeTab() {
    this.loadingDT = true;
    await this.$store.dispatch("users/clearUsers");
    this.pagination.page = 1;
    await this.getUsers(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search == null ? "" : this.search
    ).then(() => {
      this.loadingDT = false;
    });
  }

  private async searchUserByText(clear: boolean) {
    this.loadingDT = true;
    if (this.search == null || clear === true) {
      this.search = "";
    }

    await this.getUsers(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getUsers(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getUsers(page: number, size: number, text = ""): Promise<void> {
    await this.$store
      .dispatch(this.tab == 0 ? "users/getStaff" : "users/getClients", {
        page,
        size,
        text,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Users.fetchError.users"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getUsers(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    await this.$store.dispatch("roles/getAssignableRoles");
    this.loader = false;
  }

  private get headers() {
    const headers = [
      {
        text: this.$tc("Users.headers.name"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Users.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Users.headers.email"),
        value: "email",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Users.headers.comments"),
        value: "comments",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.bc-b-1"),
        value: "bill_comments",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Users.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];

    if (this.tab == 0) {
      const aux: any[] = [];
      aux.push({
        text: this.$tc("Users.headers.role"),
        value: "role",
        align: "center",
        sortable: false,
      });
      headers.forEach((header) => {
        aux.push(header);
      });
      return aux;
    } else {
      return headers;
    }
  }

  private get roles(): Role[] {
    let response = this.$store.getters["roles/getRoles"];

    if (response?.roles) {
      return response.roles;
    } else {
      return [];
    }
  }

  private get users(): User[] {
    let response = this.$store.getters["users/getUsers"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.users) {
      return response.users;
    } else {
      return [];
    }
  }

  private selectedCountry(country: any): void {
    this.phoneCountry = country;
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      (this.user.phone == null ? "" : this.user.phone) +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }

  @Watch("user.phone")
  setUserPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      clearPhone +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }

  aliasTimer = 0;
  @Watch("user.alias")
  async verifyAlias(value: string | undefined) {
    if (this.user.alias) {
      this.userValidations.loadingAlias = true;
      clearTimeout(this.aliasTimer);
      this.aliasTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: "",
            alias: value,
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.aliasExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.userValidations.alias = exists;
        this.userValidations.loadingAlias = false;
      }, 500);
    }
  }

  emailTimer = 0;
  @Watch("user.email")
  async verifyEmail(value: string | undefined) {
    const emailRegex = new RegExp(
      `^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$`
    );

    if (this.user.email && emailRegex.test(value as string)) {
      this.userValidations.loadingEmail = true;
      clearTimeout(this.emailTimer);
      this.emailTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: value,
            alias: "",
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.emailExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.userValidations.email = exists;
        this.userValidations.loadingEmail = false;
      }, 500);
    }
  }

  private async createUser() {
    this.loading = true;
    await this.verifyAlias(this.user.alias);
    await this.verifyEmail(this.user.email);
    if (
      !this.userValidations.alias &&
      !this.userValidations.email &&
      this.$refs.userForm.validate()
    ) {
      const user = { ...this.user };
      user.phone = this.formatedPhone;
      await this.$store
        .dispatch("users/createStaff", {
          role: this.role,
          user: user,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Users.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getUsers(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Users.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
  get canEdit() {
    return (
      this.user.status?.name !== this.$constants.STATUS.DELETED &&
      (this.user.role
        ? this.user.role.name !== this.$constants.USER_TYPE.OWNER
        : true)
    );
  }
  get canEditOwner() {
    return (
      this.myUser.role?.name == this.$constants.USER_TYPE.OWNER &&
      this.user.role?.name == this.$constants.USER_TYPE.OWNER &&
      this.user.status?.name == this.$constants.STATUS.INACTIVE
    );
  }
  showEdit(item: User) {
    return (
      item.status?.name !== this.$constants.STATUS.DELETED &&
      (item.role
        ? item.role.name !== this.$constants.USER_TYPE.OWNER ||
          (item.status?.name == this.$constants.STATUS.INACTIVE &&
            this.myUser.role?.name == this.$constants.USER_TYPE.OWNER)
        : true)
    );
  }
  private async editUser(user: User) {
    this.user = { ...user };
    this.formerUser = { email: this.user.email, alias: this.user.alias };
    this.user.status = { ...user.status } as Status;
    this.role = { ...user.role } as Role;
    this.dialogUpdate = true;
  }
  private showError(type = "Users.errorUpdate") {
    const Error: Notification = {
      message: this.$tc(type),
      timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
      top: true,
      bottom: false,
      left: false,
      right: false,
      currentPath: this.$route.fullPath,
      error: true,
    };

    this.$store.dispatch("notifications/showNotification", Error);
  }
  async updateEmail(finish = false) {
    if (
      !this.userValidations.editEmail &&
      this.user.email != this.formerUser.email
    ) {
      try {
        await this.$store.dispatch("users/updateUserEmail", {
          id: this.user.id,
          email: this.user.email,
        });
      } catch (error) {
        this.showError();
      } finally {
        if (finish) {
          this.loading = false;
          const Success: Notification = {
            message: this.$tc("Users.successUpdate"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getUsers(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        }
      }
    }
    if (finish) {
      this.loading = false;
    }
  }
  private async updateUser() {
    this.loading = true;
    if (this.$refs.updateForm.validate()) {
      if (this.canEditOwner) {
        await this.updateEmail(true);
        return;
      }
      await this.$store
        .dispatch("users/updateUserStatus", {
          id: this.user.id,
          status: this.user.status?.name,
        })
        .then(async () => {
          if (
            !this.userValidations.editAlias &&
            this.user.alias != this.formerUser.alias
          ) {
            try {
              await this.$store.dispatch("users/updateUserAlias", {
                id: this.user.id,
                alias: this.user.alias,
              });
            } catch (error) {
              this.showError();
            }
          }
          await this.updateEmail();
          await this.$store
            .dispatch("users/updateUserRole", {
              id: this.user.id,
              role: this.user.role
                ? this.role.name
                : this.$constants.USER_TYPE.CLIENT,
            })
            .then(async () => {
              await this.$store
                .dispatch("users/updateUserBlockComments", {
                  id: this.user.id,
                  blockProductComments: this.user.block_product_comments,
                  blockBillComments: this.user.block_bill_comments,
                })
                .then(async () => {
                  const Success: Notification = {
                    message: this.$tc("Users.successUpdate"),
                    timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
                    top: true,
                    bottom: false,
                    left: false,
                    right: false,
                    currentPath: this.$route.fullPath,
                    error: false,
                  };

                  this.$store.dispatch(
                    "notifications/showNotification",
                    Success
                  );
                  await this.getUsers(
                    this.pagination.page,
                    this.pagination.itemsPerPage == -1
                      ? this.pagination.totalItems
                      : this.pagination.itemsPerPage,
                    this.search
                  );
                  this.close();
                })
                .catch(() => {
                  this.showError();
                });
            })
            .catch(() => {
              this.showError();
            });
        })
        .catch(() => {
          this.showError();
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private deleteUserConfirm(user: User) {
    this.user = { ...user };
    this.dialogDelete = true;
  }

  private sendLink(user: User) {
    this.user = { ...user };
    this.dialogLink = true;
  }

  private kycUserValidation(user: User) {
    this.$router.push(`/staff/users/${user.id}/kyc-validation`);
  }

  private userDetail(user: User) {
    this.$router.push(`/staff/users/${user.id}/detail`);
  }
  get existentAccountEdit() {
    return this.userValidations.editAlias || this.userValidations.editEmail;
  }
  private async deleteUser() {
    this.loading = true;
    await this.$store
      .dispatch("users/updateUserStatus", {
        id: this.user.id,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Users.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getUsers(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Users.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async sendUserValidationLink() {
    this.loading = true;
    await this.$store
      .dispatch("users/sendUserValidationLink", this.user.id)
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Views.ur-4"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getUsers(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.closeLink();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ur-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.user = Object.assign(this.user, this.defaultUser);
    this.role = Object.assign(this.role, this.defaultRole);
  }

  private closeLink() {
    this.dialogLink = false;
    this.user = Object.assign(this.user, this.defaultUser);
    this.role = Object.assign(this.role, this.defaultRole);
  }

  resetValidations() {
    this.resetFormValidations([this.$refs.userForm, this.$refs.updateForm]);
  }

  private close() {
    try {
      this.resetValidations();
      this.resetForms();
    } catch (error) {
      this.resetForms();
    }
  }

  private resetForms() {
    if (this.dialog) {
      this.dialog = false;
    } else if (this.dialogUpdate) {
      this.dialogUpdate = false;
    }
    this.user = Object.assign(this.user, this.defaultUser);
    this.role = Object.assign(this.role, this.defaultRole);
    this.userValidations.alias = false;
    this.userValidations.email = false;
    this.userValidations.loadingEmail = false;
    this.userValidations.loadingAlias = false;
  }
}
